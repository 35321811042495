
<div class="header">
    <div class="header-main">
        <div> <span class="account-name">{{account?.info.name}}</span></div>
        <div class="bar"></div>
        <div class="right">
            <div class="account-info-button" (click)="menu.toggle($event)">
                <ng-icon name="saxProfileCircleOutline" size="24"></ng-icon>
                <div>{{user?.alias}}</div>
                <ng-icon name="saxArrowDown1Outline" size="24"></ng-icon>
            </div>
            <p-menu #menu [model]="items" [popup]="true">
                <ng-template pTemplate="item" let-item>
                    <div (click)="openMenu(item)" pRipple class="flex items-center p-menu-item-link">
                        <ng-icon [name]="item.icon" size="20"></ng-icon>
                        <span class="ml-2 menu-label">{{ item.label }}</span>
                    </div>
                    <p-divider *ngIf="item.line" type="solid" style="margin: 0;" />
                </ng-template>
            </p-menu>
        </div>
    </div>
</div>
<div class="router-body" (click)="closeMenu($event)" (dragover)="commonService.onDragOver($event)" (dragenter)="commonService.onDragEnter($event)" (dragleave)="commonService.onDragLeave($event)" (drop)="commonService.onDrop($event)">
    <div class="router-child">
        <router-outlet></router-outlet>
    </div>
</div>
<!-- <div class="dash-header">
    <h2>Partner Dashboard</h2>
    <p>Welcome to your partner dashboard.</p>
</div> -->
<div class="main-container mt-14">
    <div>
        <div class="dash-icons">
            <div class="dash-icon" (click)="loadCompent('accounts')">
                <ng-icon name="saxProfileCircleOutline" size="32"></ng-icon>
                <span>Accounts</span>
            </div>     
            <div class="dash-icon" (click)="loadCompent('settings')">
                <ng-icon name="saxSetting2Outline" size="32"></ng-icon>
                <span>Settings</span>
            </div>
        </div>
    </div>
</div>